<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="850"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="1"
        color="primary"
        small
        outlined
        v-bind="attrs"
        v-on="on"
      >{{$_strings.selectioninfo.button.STATUS}}</v-btn>
    </template>
    <v-card>
      <div class="btn-close">
        <v-btn
          text
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="d-flex justify-center">
        <span class="headline d-flex">
          {{$_strings.selectioninfo.label.UPLOADED}}
        </span>
      </v-card-title>
      <div class="w-100 d-flex justify-end">
        <div class="w-30 mr-10">
          <v-select
            v-if="!isDisplayDetail && dataTypeOptions.length"
            v-model="csvStatusListFilters"
            :items="dataTypeOptions"
            item-text="label"
            item-value="dataType"
            placeholder="Select"
            outlined
            hide-details="true"
            @change="fetchCsvStatusList(1)"
          ></v-select>
        </div>
      </div>
      <v-card-text>
        <v-container>
          <v-btn
            v-if="isDisplayDetail"
            class="mb-5"
            color="secondary"
            elevation="2"
            outlined
            plain
            small
            tile
            @click="clickedCsvStatusList"
          >Csv status list</v-btn>
          <v-data-table
            v-if="!isDisplayDetail"
            :headers="headersStatusData"
            :items="itemsStatusData"
            :loading="isLoading"
            @click:row="handleCsvStatusRowClick"
            :options.sync="paginationHeader"
            :server-items-length="totalEntryHeader"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            class="elevation-1"
          ></v-data-table>
          <v-data-table
            v-else
            :headers="headersStatusDataDetail"
            :items="itemsStatusDataDetail"
            :loading="isLoading"
            :options.sync="paginationDetail"
            :server-items-length="totalEntryDetail"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            class="elevation-1"
          ></v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '../../helper/commonHelpers';

export default {
  name: 'uploaded-csv-status-dialog',
  props: {
    dataType: String,
    importType: String,
  },

  data() {
    return {
      rowId: null,
      dialog: false,
      isDisplayDetail: false,
      isLoading: false,
      headers: [],
      headersStatusData: [
        { text: this.$_strings.selectioninfo.headers.UPLOAD_BY, value: 'createdBy', cellClass: 'clickable' },
        { text: this.$_strings.selectioninfo.headers.UPLOAD_AT, value: 'timeUpload', cellClass: 'clickable' },
        { text: this.$_strings.selectioninfo.headers.STATUS, value: 'processStatus', cellClass: 'clickable' },
        { text: this.$_strings.selectioninfo.headers.SUCCESS, value: 'successRecord', cellClass: 'clickable' },
        { text: this.$_strings.selectioninfo.headers.FAILED, value: 'failedRecord', cellClass: 'clickable' },
      ],
      itemsStatusData: [],
      headersStatusDataDetail: [
        { text: this.$_strings.selectioninfo.headers.UPLOAD_BY, value: 'createdBy' },
        { text: this.$_strings.selectioninfo.headers.UPLOAD_AT, value: 'timeUpload' },
        { text: this.$_strings.selectioninfo.headers.STATUS, value: 'importStatus' },
        { text: this.$_strings.selectioninfo.headers.DESCRIPTION, value: 'description' },
      ],
      itemsStatusDataDetail: [],
      paginationHeader: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      paginationDetail: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalEntryHeader: 0,
      totalEntryDetail: 0,
      csvStatusListFilters: null,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.dialogOpened();
        this.fetchCsvStatusList();
      }
      if (!newVal) this.rowId = null;
    },
    paginationHeader: {
      handler(oldVal, newVal) {
        const isSame = JSON.stringify(newVal) === JSON.stringify(oldVal);
        if (!this.rowId && !isSame) {
          this.fetchCsvStatusList();
        }
      },
      deep: true,
    },
    paginationDetail: {
      handler(oldVal, newVal) {
        const isSame = JSON.stringify(newVal) === JSON.stringify(oldVal);
        if (this.rowId && !isSame) {
          this.fetchCsvDetailList();
        }
      },
      deep: true,
    },
  },
  computed: {
    dataTypeOptions() {
      return this.$_strings.csvUpload.csvDataTypeOptions.filter(
        (i) => i.databaseType === this.dataType,
      );
    },
  },
  methods: {
    dateFormat,
    clickedCsvStatusList() {
      this.rowId = null;
      this.isDisplayDetail = false;
      this.paginationDetail = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      };
    },
    handleCsvStatusRowClick(row) {
      if (!this.isDisplayDetail) {
        this.rowId = row.id;
        this.fetchCsvDetailList();
      }
    },
    fetchCsvStatusList(_page = null) {
      if (_page) this.paginationHeader.page = _page;
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.paginationHeader;
      let sort = 'timeUpload,DESC';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      const filters = {
        page,
        itemsPerPage,
        sort,
      };
      this.isLoading = true;
      this.$_services.uploads
        .fetchUploadedCsvDataProcessStatus(filters, this.csvStatusListFilters)
        .then((result) => {
          this.isDisplayDetail = false;
          this.itemsStatusData = result.data.contents.map((e) => (
            {
              id: e.id,
              createdBy: e.createdBy,
              timeUpload: `${this.dateFormat(e.timeUpload)} ${dayjs(e.timeUpload).format('HH:mm')}`,
              processStatus: e.processStatus,
              successRecord: e.successRecord,
              failedRecord: e.failedRecord,
            }
          ));
          this.totalEntryHeader = result.data.totalData;
        })
        .finally((i) => {
          this.isLoading = false;
        });
    },
    fetchCsvDetailList() {
      const {
        sortBy, sortDesc, itemsPerPage, page,
      } = this.paginationDetail;
      let sort = 'createdAt,DESC';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      const filters = {
        page,
        itemsPerPage,
        sort,
        id: this.rowId,
      };
      this.isLoading = true;
      this.$_services.uploads
        .fetchUploadedCsvDataProcessDetail(filters, this.importType)
        .then((result) => {
          this.isDisplayDetail = true;
          this.itemsStatusDataDetail = result.data.contents.map((e) => (
            {
              createdBy: e.createdBy,
              timeUpload: `${this.dateFormat(e.createdAt)} ${dayjs(e.createdAt).format('HH:mm')}`,
              importStatus: e.importStatus,
              description: `Baris ke-${e.rowData} ${e.description}`,
            }
          ));
          this.totalEntryDetail = result.data.totalData;
        }).finally((i) => {
          this.isLoading = false;
        });
    },
    dialogOpened() {
      if (
        this.dataType === 'customer'
        || this.dataType === 'selection_info'
        || this.dataType === 'mou'
      ) {
        this.csvStatusListFilters = this.dataTypeOptions[0].dataType;
      } else {
        this.csvStatusListFilters = this.dataType;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-close{
  position: absolute;
  right: 0;
}
.style-status-failed {
  background-color: #fef0f0
}
.v-data-table.cursor-pointer tbody tr{
  cursor: pointer;
}
::v-deep {
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #aaa;
  }
  .theme--light.v-data-table
    .v-data-table-header
    th.sortable.active
    .v-data-table-header__icon {
    color: black !important;
  }
}
</style>
